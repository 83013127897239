import React from 'react';

export const About = () => {

    const sliderData =  [
            {
                id: 1,
                image: './images/1.jpg',
                alt: ''
            },
            {
                id: 2,
                image: './images/2.jpg',
                alt: ''
            },
            {
                id: 3,
                image: './images/3.jpg',
                alt: ''
            },
            {
                id: 4,
                image: './images/4.jpg',
                alt: ''
            },
            {
                id: 5,
                image: './images/5.jpg',
                alt: ''
            }
        ];

    return (
        <div className="section slide-personal-Intro-fourth slide-dark">
            <section className="flat-case-study flex s1 position-relative" id="about">
                <div className="container d-lg-flex">
                    {/* Left */}
                    <div className="col-left animate-element wow delay5 fadeInDown" data-wow-delay="0.5s">
                        <div className="flat-title t1">
                            <h2 className="title-section color-d19 mg-b34">About</h2>
                            <div className="description">
                                <p className="lt-sp01 color-d19">
                                    <i className="fa fa-chevron-circle-right mr-3" aria-hidden="true" />Matrix from N is a collection of 8888 unique matrices, generated from N-Project numbers
                                </p>                                
                                <p className="lt-sp01 color-d19">
                                    <i className="fa fa-chevron-circle-right mr-3" aria-hidden="true" />Owners of Matrix tokens receive exclusive access to airdrops and early presales of all our other NFT collections
                                </p>
                                <p className="lt-sp01 color-d19">
                                    <i className="fa fa-chevron-circle-right mr-3" aria-hidden="true" />Matrices are available directly from smart contract, so developer can use it for any purposes
                                </p>                                
                            </div>
                        </div>
                    </div>

                    {/* Right */}
                    <div className="col-right animate-element wow delay5 fadeInUp" data-wow-delay="0.5s">
                        <div className="flat-spacer" data-desktop="4" data-mobile="70" data-smobile="70"></div>
                        <div className="slider-drop-apps custom-dot dot-t2 dot-s2 number-pages none-nav-default mg-dots-s2">
                            <div className="flat-carousel-box data-effect clearfix" data-gap="0" data-column="1" data-column2="1" data-column3="1" data-column4="1" data-column5="1" data-dots="true" data-auto="false" data-nav="false" data-loop="true">
                                <div className="owl-carousel">
                                    {
                                        sliderData.map(data => (
                                            <div className="featured-post" key={data.id}>
                                                <img src={data.image} alt={data.alt} />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    )
}