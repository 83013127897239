import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export const Header = (props) => {

    const menus = [
        {
            id: 1,
            tomenu: "#about",
            namemenu: "About"
        },       
        {
            id: 3,
            tomenu: "#mint",
            namemenu: "Mint"
        },
        
    ];

    const handleWalletClicked = () => {
        props.onWalletClicked();
    }
    
    let ws = props.walletStr;
    if(ws.length == 0)
        ws = "Connect wallet";
    if(ws.startsWith('0x'))
        ws = ws.substring(0, 4) + '...' + ws.substring(ws.length - 3);

    return (
        <header id="header" className="header header-style4 has-menu-model">
            <div className="container">
                <div className="flex-header d-flex justify-content-between align-items-center">
                    <div id="logo" className="logo">
                        <Link to="/" onClick={() => {window.location.href="/"}} title="Logo"><img src="images/logo/logo.png" alt="images" data-width={250} data-height={60} /></Link>
                    </div>
                    <div dir="rtl" className="btn-menu mobile-header__menu-button">
                        <div className="line line-1" />
                        <div className="line line-2" />
                        <div className="line line-3" />
                        <div className="line line-4" />
                    </div>
                    <div className="content-menu d-lg-flex">
                        <div className="nav-wrap">
                            <nav id="mainnav" className="mainnav">
                                <ul className="menu ace-responsive-menu" data-menu-style="horizontal">
                                    {
                                        menus.map(menu => (
                                            <li key={menu.id}><Link to={menu.tomenu} className="click-model">{menu.namemenu}</Link></li>
                                        ))
                                    }                           
                                    
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="button-wallet btn-general btn-hv-common d-lg-flex align-items-center">
                        <Link to="#" className="btn-inner border-corner2 lt-sp08 text-white" onClick={handleWalletClicked}>{ws}</Link>
                    </div>
                    
                </div>
            </div>
        </header>
    );
}

