import React, { useState, useEffect } from "react";
import { connectWallet, getAmountOfN, getCurrentWalletConnected, getMatrixList, getMintedAmount, getNList, getPublicSaleStatus, mintNFT } from "../../utils/interact";
import { About } from "./About";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Mint } from "./Mint";
import { Stat } from "./Stat";

export const Main = (props) => {
    const [price, setPrice] = useState('0.08');
    //const [specialPrice, setSpecialPrice] = useState(false);
    const [mintedNum, setMintedNum] = useState(0);
    const [remainNum, setRemainNum] = useState(0);
    const [publicSaleActive, setPublicSaleActive] = useState(false);
    const [walletAddress, setWalletAddress] = useState("");
    const [status, setStatus] = useState();
    
    const [nArray, setNArray] = useState([]);
    const [mArray, setMArray] = useState([]);


    useEffect(async () => {
        const supply = await getMintedAmount();
        setMintedNum(supply);
        setRemainNum(8888 - supply);
        
        //при запасе нагрузки расскоментировать
        //const ps = false;//await getPublicSaleStatus();
        const ps = await getPublicSaleStatus();
        setPublicSaleActive(ps);
        
        const walletStatus = await getCurrentWalletConnected();
        setWalletAddress(walletStatus.address);

        addWalletListener();

        //const nList = await getNList();
        //setNArray(nList);

        //const mList = await getMatrixList();
        //setMArray(mList);
    }, []);
    useEffect(async () => {
        console.log("Wallet effect");
        if(walletAddress != "")
        {
            const nList = await getNList();
            setNArray(nList);

            //если в N пусто, то нам и не нужен наш список
            if(nList > 0) {
                const mList = await getMatrixList();
                setMArray(mList);
            }
        }
        else {
            setNArray([]);
            setMArray([]);
        }
    }, [walletAddress])

    const handleWalletClicked = async() => {
        if(walletAddress == "")
            connectWallet();
    }

    const handleMintClicked = async (num) => {
        
        if(walletAddress == "")
            connectWallet();
        else
            mintNFT();
    }


    return (
        <div className="counter-scroll background-wrap-home4 fixed counter-scroll home4 has-one-page">               
            <div id="mobile-menu-overlay" />
            <Header walletStr={walletAddress} onWalletClicked={handleWalletClicked}></Header>
            <div id="content" className="content">
                <div className="homepage-personal" style={{backgroundColor:'#171a2b'}}>
                    <About></About>
                    {
                    //<Stat total={8888} available={remainNum} sold={mintedNum} publicSale={publicSaleActive}></Stat>
                    }
                    <Mint nList={nArray} matrixList={mArray} onMintClicked={handleMintClicked} publicSale={publicSaleActive}></Mint>
                    <Footer></Footer>
                </div>
            </div>
        </div>
    );

    function addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    setWalletAddress(accounts[0]);
                    //setStatus("👆🏽 Write a message in the text-field above.");
                } else {
                    setWalletAddress("");
                    //setStatus("🦊 Connect to Metamask using the top right button.");
                }
            });
        } else {
            setStatus(
                <p>
                    {" "}
                    🦊{" "}
                    <a target="_blank" href={`https://metamask.io/download.html`}>
                        You must install Metamask, a virtual Ethereum wallet, in your
                        browser.
                    </a>
                </p>
            );
        }
    }
}