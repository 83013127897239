import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { checkTokenAvailable, isOwnerOfN, mintNFT } from '../../utils/interact';

export const Mint = (props) => {

    const [enteredNumber, setEnteredNumber] = useState(1);
    const [imagePath, setImagePath] = useState("./images/MatrixEmpty.png");
    const [status, setStatus] = useState("");
    const [etherscanLink, setEtherscanLink] = useState("");
    const [priceStr, setPriceStr] = useState("")

    const inputRef = useRef(null);
    var updateImageTimeout = -1;
    var curText = ""

    const handleNumberEntered = (e) => {
        var text = e.target.value;
        if(isNaN(e.nativeEvent.data))
        {
            inputRef.current.value = curText;
            setPriceStr("");
            return;
        }
        curText = text;

        let n = parseInt(curText);
        if(n < 1 || n > 8888 || isNaN(n))
        {
            setStatus("Enter a number between 1 and 8888");
            setImagePath("./images/MatrixEmpty.png");
            if(updateImageTimeout >= 0)
                clearTimeout(updateImageTimeout);
            setPriceStr("");
            return;
        }
        else
            setStatus("");

        if(props.nList.includes(curText)) {
            setPriceStr(" for 0.03 ETH")
        }
        else {
            //replace after public sale starts
            if(props.publicSale)
                setPriceStr(" for 0.08 ETH")
            else
                setPriceStr("");
        }

        if(updateImageTimeout >= 0)
            clearTimeout(updateImageTimeout);
        
        updateImageTimeout = setTimeout(() => {
            setEnteredNumber(text);
            if(text != "")
                setImagePath("https://matrixfromn.com/meta/" + text + ".jpg");
            else
                setImagePath("./images/MatrixEmpty.png");
        }, 200);
    }

    const handleNCircleClicked = (n) => {
        if(props.matrixList.includes(n))
            return;

        inputRef.current.value = n.toString();
        curText = inputRef.current.value;

        if(props.nList.includes(curText)) {
            setPriceStr(" for 0.03 ETH")
        }
        else {
            setPriceStr(" for 0.08 ETH")
        }

        if(updateImageTimeout >= 0)
            clearTimeout(updateImageTimeout);
        
        updateImageTimeout = setTimeout(() => {
            setEnteredNumber(curText);
            if(curText != "")
                setImagePath("https://matrixfromn.com/meta/" + curText + ".jpg");
            else
                setImagePath("./images/MatrixEmpty.png");
        }, 100);
    }

    let tokensButtons = [];
    for(let i = 0; i < props.nList.length; i++) {
        if(props.matrixList.includes(props.nList[i]))
            tokensButtons.push({id: props.nList[i], className: "circle-button-disabled"})
        else
            tokensButtons.push({id: props.nList[i], className: ""})
    }

    const handleMintClicked = async() => {
        if(inputRef.current.value.length == 0) {
            setStatus("Enter number between 1 and 8888");
            return;
        }
        if(isNaN(inputRef.current.value) == false) {
            try {
                let avail = await checkTokenAvailable(inputRef.current.value);
                if(avail) {
                    if(props.publicSale == false) {
                        const hasSameN = await isOwnerOfN(inputRef.current.value);
                        if(hasSameN) {
                            //props.onMintClicked(inputRef.current.value);
                            const ret = await mintNFT(inputRef.current.value, true);
                            setStatus(ret.status);
                            setEtherscanLink(ret.link);
                        }
                        else {
                            setStatus("Only holders of The N Project tokens are able to mint Matrix tokens with corresponds IDs during presale.")
                        }
                    }
                    else {
                        const hasSameN = await isOwnerOfN(inputRef.current.value);
                        if(hasSameN) {
                            //props.onMintClicked(inputRef.current.value);
                            const ret = await mintNFT(inputRef.current.value, true);
                            setStatus(ret.status);
                            setEtherscanLink(ret.link);
                        }
                        else {
                            const ret = await mintNFT(inputRef.current.value, false);
                            setStatus(ret.status);
                            setEtherscanLink(ret.link);
                        }
                        //props.onMintClicked(inputRef.current.value);
                    }
                }
                else
                    setStatus("Token #" + inputRef.current.value + " has already been minted");
            }
            catch (e) {
                
            }
            
        }
        else {
            setStatus("Enter number between 1 and 8888");
        }
    }

    return (
        <div className="section slide-personal-Intro-fourth slide-dark">
            <section className="flat-case-study flex s1 position-relative pt-4 mt-4" id="mint">
                <div className="container d-lg-flex">
                    {/* Left */}
                    <div className="col-left animate-element wow delay5 fadeInDown" data-wow-delay="0.5s">
                        <div className="flat-title t1">
                            <h2 className="title-section color-d19 mg-b34">Mint</h2>
                           
                            <div className="description">
                                <p className="lt-sp01 color-d19">
                                    <i className="fa fa-chevron-circle-right mr-3" aria-hidden="true" />Enter token number to check the Matrix and mint it.
                                </p>                                
                                <p className="lt-sp01 color-d19">
                                    <i className="fa fa-chevron-circle-right mr-3" aria-hidden="true" />Matrix tokens are available for everyone for 0.08 ETH + gas
                                </p>
                                <p className="lt-sp01 color-d19">
                                    <i className="fa fa-chevron-circle-right mr-3" aria-hidden="true" />N tokens holders can mint Matrix for 0.03 ETH + gas
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Right */}
                    <div className="col-right animate-element wow delay5 fadeInUp" data-wow-delay="0.5s">
                        <div className="row">
                                <div className="col-1"></div>
                                <div className="col-5"> 
                                    <input ref={inputRef} className="mb-1" type="text" placeholder="#" style={{fontSize:'32px', fontWeight:600, fontFamily:'Roboto', width:400, height:60, textAlign:'center'}} onChange={handleNumberEntered} />
                                </div>
                                <div className="col-5 d-flex justify-content-center" > 
                                    <div className="button-mint-new btn-general btn-hv-common align-items-center">
                                        <Link to="#" className="btn-inner border-corner2 lt-sp08 text-white text-center align-self-center" style={{width:200, height:'60px', lineHeight:'60px'}} onClick={handleMintClicked}>Mint{priceStr}</Link>
                                    </div>
                                </div>
                                <div className="col-1"></div>
                            </div>
                        <div>
                        <div className="row">
                            <div className="col-12 color-green text-center">{status}</div>
                        </div>
                        <div className="row">
                            <div className="col-12 color-green text-center">
                            {
                                etherscanLink.length > 1 && 
                                <a href={etherscanLink} className="green-link">{etherscanLink}</a>
                            }
                            </div>
                            
                        </div>
                        {
                            tokensButtons.length > 0 && 
                            <>
                            <div className="row mt-2">
                                <div className="col-12 text-center"><p className="h5 color-d19">Your tokens in The N Project:</p></div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <div className="socials-list-hd s3 hv2">
                                    {
                                        tokensButtons.map(token => (
                                            
                                            <Link to="#" key={token.id} className={token.className} onClick={ () => handleNCircleClicked(token.id)}>{token.id}</Link>
                                        ))
                                    }
                                    </div>
                                </div>
                            </div>
                            </>
                        }
                        <div className="mt-3" style={{width:500, height:500}}>
                        {
                            imagePath != "" && 
                            <img src={imagePath}  style={{borderRadius:20}}></img>
                        }
                        </div>
                        </div>
                        
                    
                    </div>
                </div>
            </section>
        </div>
    )
    /*
    return (
        <div className="section slide-personal-Intro-third slide-dark">
                <section className="specilizing-in s1" id="services">
                    <div className="container">
                        <div className="flat-title">
                            <h2 className="title-section color-d19 animate-element wow delay5 fadeInDown" data-wow-delay="0.5s">Mint</h2>
                        </div>
                        {props.list.length > 0 && 
                        <div className="row position-relative  d-flex justify-content-center">
                        {
                            props.list.map(token => (
                                <div className="button-mint-excited btn-general btn-hv-common d-lg-flex align-items-center" key={token}>
                                    <Link to="#" className="btn-inner border-corner2 lt-sp08 text-white text-center"><p>Mint</p><p>Matrix #{token}</p><p>0.03 ETH</p></Link>
                                </div>
                            ))        
                        }
                        </div>
                        }

                        {props.list.length > 0 && 
                        <div className="row position-relative  d-flex justify-content-center">
                            <div className="col-4">
                                <input className="mb-4" type="email" placeholder="Enter token #" style={{fontSize:'24px'}}/>
                                <div className="button-mint-new btn-general btn-hv-common  align-items-center">
                                    <Link to="#" className="btn-inner border-corner2 lt-sp08 text-white text-center"><p>Mint</p><p>0.08 ETH</p></Link>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    
                </section>
            </div>
    )
    */
}