import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export const Footer = () => {

    return (
        <footer id="footer" className="footer footer-s1">
            <div id="footer-widget" className="footer-widget-s2 footer-widget-line bg-s1 position-relative">
                <div className="container">
                    <div className="site-list site-list-center text-center">
                        <Link to={{ pathname: "https://twitter.com/innofty" }} target="_blank" className="bg-s1"><i className="fa fa-twitter" aria-hidden="true" /></Link>
                        <Link to={{ pathname: "https://discord.gg/UJNqWCXRuZ" }} className="bg-s2" style={{backgroundColor:'#5865F2'}}><i><img src="images/Discord.svg" alt="images" width={32} style={{marginBottom:8}} /></i></Link>
                        <Link to={{ pathname: "https://opensea.io/collection/matrix-from-n" }} target="_blank" ><i><img src="images/Opensea.svg" alt="images" width={50} style={{marginBottom:8}} /></i></Link>
                        <Link to={{ pathname: "https://etherscan.io/address/0xb9178ce2ed2fc3ba9cbcb0ab2159b4c19e2a3c65" }} target="_blank" ><i><img src="images/Etherscan.svg" alt="images" width={50} style={{marginBottom:8}} /></i></Link>
                    </div>
                </div>
            </div>
            <div id="bottom" className="bottom-s2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="copyright lt-sp02">
                            <span className="color-green">© Innofty, 2021</span>
                            
                            </div>
                        </div>
                        <div className="col-lg-6">
                            
                        </div>
                        <div className="col-lg-2">
                            <div className="socials-list color-s1">
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}